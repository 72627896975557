<template>
  <div id="right-nav-page">
    <ul class="icons">
      <li class="tel phone">
        <div class="aa"></div>
        <div style="font-size: 12px; margin: 8px 0">电话咨询</div>
        <p><span>13808805396</span></p>
      </li>
      <li
        class="tel contact"
        @mouseenter="handleMouseEnter(3)"
        @mouseleave="handleMouseLeave"
      >
        <div class="cc"></div>
        <div style="font-size: 12px; margin: 8px 0">在线咨询</div>
        <p>
          <img
            style="width: 100px"
            src="../../assets/newSite/home/codenew.png"
          />
        </p>
      </li>
      <li
        class="mes message"
        @mouseenter="handleMouseEnter(3)"
        @mouseleave="handleMouseLeave"
        @click="handleLeaveMeassge"
      >
        <div class="ff"></div>
        <div style="font-size: 12px; margin: 8px 0">免费试用</div>
      </li>

      <li class="tel back" @click.stop="toTop">
        <div class="ee"></div>
        <div style="font-size: 12px; margin: 8px 0">返回顶部</div>
      </li>
    </ul>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      interval: null,
      isMoving: false,
      isFocus: false,
    };
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    handleLeaveMeassge() {
      this.$emit("handleMsg");
    },
    handleTest() {
      window.open("https://apipecloud.veiban.com/login");
    },
    handleSite() {
      window.open("https://epipe.cn");
    },
    handleMouseEnter(idx) {
      this.isFocus = true;
    },
    handleMouseLeave() {
      this.isFocus = false;
    },
    toTop() {
      document.documentElement.scrollTop = 0;
    },
  },
};
</script>
  
  <style scoped lang="less">
.el-input__inner {
  background: transparent !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  // color: white;
  border: 1px solid rgba(255, 255, 255, 0.3) !important;
}

#right-nav-page {
  .aa {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/tel.png");
    background-size: contain;
    background-position: center;
  }
  .cc {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/contact.png");
    background-size: contain;
    background-position: center;
  }
  .ee {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/back.png");
    background-size: contain;
    background-position: center;
  }
  .ff {
    width: 30px;
    height: 30px;
    margin: 0 auto;
    background-image: url("../../assets/newSite/rightNav/message.png");
    background-size: contain;
    background-position: center;
  }

  .message:hover {
    .ff {
      background-image: url("../../assets/newSite/rightNav/message-s.png");
    }
  }

  .phone:hover {
    .aa {
      background-image: url("../../assets/newSite/rightNav/tel-s.png");
    }
  }

  .contact:hover {
    .cc {
      background-image: url("../../assets/newSite/rightNav/contact-s.png");
    }
  }

  .back:hover {
    .ee {
      background-image: url("../../assets/newSite/rightNav/back-s.png");
    }
  }
  position: fixed;
  top: 65%;
  right: 0px;
  width: 80px;
  height: 200px;
  z-index: 150;
  ul.icons {
    position: absolute;
    z-index: 180;
    list-style: none;
    right: 10px;
    padding: 0;
    width: 80px;
    border: 1px solid #dfe1e6;
    border-right: none;
    background: white;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
    -o-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
    li {
      // margin-bottom: 10px ;
      padding-top: 10px;
      z-index: 185;
      border-bottom: 1px solid #dfe1e6;
      cursor: pointer;
      position: relative;
      i {
        display: block;
        position: relative;
        width: 50px;
        height: 50px;
        //   background: url(../../assets/components/icons.png) no-repeat;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        -ms-transition: all 0.3s ease-in-out 0s;
        -o-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
        z-index: 110;
      }
      p {
        display: block;
        position: absolute;
        top: 0;
        right: -260px;
        padding: 15px 20px;
        height: auto;
        color: White;
        line-height: 30px;
        border: 1px solid #dfe1e6;
        background: #111;
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        -moz-opacity: 0;
        opacity: 0;
        filter: alpha(opacity=0);
        -webkit-transition: all 0.4s ease-in-out 0.1s;
        -moz-transition: all 0.4s ease-in-out 0.1s;
        -ms-transition: all 0.4s ease-in-out 0.1s;
        -o-transition: all 0.4s ease-in-out 0.1s;
        transition: all 0.4s ease-in-out 0.1s;
        z-index: 999;
      }
    }
    li:hover {
      color: white;
      background: #1484ff;
    }
    li.tel {
      p {
        width: 200px;
        font-family: inherit;
        color: black;
        background: #ffffff;
        span {
          font-size: 20px;
        }
      }
      &:hover p {
        z-index: 100;
        right: 90px !important;
        opacity: 1;
      }
    }
    li.wechat {
      p {
        width: 200px;
        img {
          width: 115px;
          border: 2px solid White;
        }
      }
      i {
        background-position: -59px -66px;
      }
      &:hover p {
        z-index: 140;
        right: 50px;
        opacity: 1;
      }
    }
    li.mes {
      p {
        width: 200px;
        img {
          width: 115px;
          border: 2px solid White;
        }
      }
      i {
        background-position: -59px -66px;
      }
      &:hover p {
        z-index: 140;
        right: 50px;
        opacity: 1;
      }
    }
    li.up {
      z-index: 500;
      i {
        background-position: -63px -150px;
      }
    }
  }

  a.switch {
    position: absolute;
    display: block;
    top: 270px;
    right: 11px;
    width: 26px;
    height: 26px;
    // background: url(../../assets/components/icons.png) no-repeat 0px -640px;
    cursor: pointer;
    -webkit-transition-delay: 0.15s;
    -moz-transition-delay: 0.15s;
    -ms-transition-delay: 0.15s;
    -o-transition-delay: 0.15s;
    transition-delay: 0.15s;
  }
  a.switch.off {
    background-position: -32px -640px;
  }
  .close {
    z-index: 50;
  }
  &.close ul.icons {
    right: -70px;
  }
}
</style>
  