import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueImg from "v-img";

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import RightNavNew from "@/components/RightNavNew/index.vue";
import TopNavNew from "@/components/TopNavNew/index.vue";
import NewFooter from "@/components/footernew.vue";
import MobileFooter from "@/components/mobileFooter.vue";
import MessageInfo from "@/components/message.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.component("RightNavNew", RightNavNew);
Vue.component("TopNavNew", TopNavNew);
Vue.component("NewFooter", NewFooter);
Vue.component("MobileFooter", MobileFooter);
Vue.component("MessageInfo", MessageInfo);
Vue.config.productionTip = false;
const vueImgConfig = {
  altAsTitle: true,
};
Vue.prototype.$BaseUrl = 'https://admin.veiban.com'

Vue.use(VueImg, vueImgConfig);
// Vue.use(Bootstrap);
Vue.use(ElementUI);



// 确保在Vue实例创建之前添加百度统计代码
window._hmt = window._hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?8bcf40a52c16a4c105751e9f9c7194b4";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();


import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
Vue.prototype.$axios = axios;
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
