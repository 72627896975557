import http from "@/utils/http";
const fixedPath = process.env.VUE_APP_BASE_API

export function clickzan(params) {
  return http.get(`${fixedPath}/cms/content/like.do`, params);
}
//是否点赞
export function isLike(params) {
  return http.get(`${fixedPath}/cms/content/like/check.do`, params);
}
//留言 /wbgw/contact/message/submit
export function leaveMessage(params) { 
  return http.post(`${fixedPath}/contact/message/submit`, params);
}
export function Newslist(params) {
  return http.post(`${fixedPath}/news/management/pageList`, params);
}
//成功案例
export function caselist(params) {
  return http.post(`${fixedPath}/news/management/successCasePageList`, params);
}
// 新闻详情
export function Newsdetail(params) {
  return http.post(`${fixedPath}/news/management/newsDetail`, params);
}
export default fixedPath