<template>
  <div>
    <nav
      :class="[
        'navbar',
        'fixed-top',
        isTop ? 'whiteStyle' : 'bg-body-tertiary',
        'navbar-expand-lg',
        'd-none',
        'd-lg-block',
      ]"
      style="padding: 0 20px"
    >
      <div class="d-none d-lg-block">
        <div
          v-show="hoverKey == 1"
          class="product-borad"
          @mouseenter="handleMouseEnter(1)"
          @mouseleave="handleMouseLeave"
        >
          <div class="main-conten">
            <div class="main-plan">
 
              <div class="content">
                <div class="items">
                  <div @click="()=>{this.openNewWindow('/mes');}">智能制造MES</div>
                  <div @click="()=>{this.openNewWindow('/wms');}">仓储管理WMS</div>
                  <div @click="()=>{this.openNewWindow('/isc');}">集成供应链ISC</div>
                  <div @click="()=>{this.openNewWindow('/erp');}">企业资源计划ERP</div>
                  <div @click="()=>{this.openNewWindow('/plm');}">研发管理PLM</div>
                </div>
                <div class="items">
                  <div @click="()=>{this.openNewWindow('/hrm');}">人力资源HRM</div>
                  <div @click="()=>{this.openNewWindow('/crm');}">客户关系CRM</div>
                  <div @click="()=>{this.openNewWindow('/fims');}">财务管理FIMS</div>
                  <div @click="()=>{this.openNewWindow('/ibms');}">智慧园区IBMS</div>
                  <!-- <div
                    style="color: #1584ff"
                    @click="
                      () => {
                        this.openNewWindow('/smartOffice');
                      }
                    "
                  >
                    了解详情..
                  </div> -->
                </div>
              </div>
            </div>
 
          </div>
        </div>
      <!-- </Transition> -->
        <div
          class="resolve-borad"
          v-show="hoverKey == 2"
          @mouseenter="handleMouseEnter(2)"
          @mouseleave="handleMouseLeave"
        >
          <div class="main-conten">
            <div class="ways">
              <!-- <span class="item">智能制造-解决方案</span>
              <span class="item">智能办公-解决方案</span>
              <span class="item">智慧园区-解决方案</span> -->
            </div>
          </div>
        </div>
        <div
          class="about-borad"
          v-show="hoverKey == 4"
          @mouseenter="handleMouseEnter(4)"
          @mouseleave="handleMouseLeave"
        >
          <div class="main-conten">

          </div>
        </div>
      </div>
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img
            v-show="isTop"
            src="@/assets/newSite/topNav/logodefault.png"
            style="width: 124px; height: 40px"
            alt=""
          />
          <img
            v-show="!isTop"
            src="@/assets/newSite/topNav/logoactive.png"
            style="width: 124px; height: 40px"
            alt=""
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
          <ul
            class="navbar-nav mb-lg-0"
            style="width: 100%; justify-content: end"
          >
            <li
              style="cursor: pointer"
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(0)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 0 }"
              @click="
                () => {
                  this.$router.push('/');
                }
              "
            >
              <a class="nav-link active">首页</a>
            </li>
            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(1)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 1 }"
              @click="
                () => {
                  // this.$router.push('/VeiBan');
                }
              "
            >
              <a class="nav-link" href="javascript:;"> 产品中心 </a>
              <img
                class="css_translateZ"
                :src="isTop ? downImgw : downImg"
                alt=""
              />
            </li>
            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(3)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 3 }"
              @click="
                () => {
                  this.openNewWindow('/case');
                }
              "
            >
              <a class="nav-link" href="javascript:;">客户案列</a>
            </li>
            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(5)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 5 }"
              @click="
                () => {
                  this.openNewWindow('/contact');
                }
              "
            >
              <a class="nav-link" href="javascript:;">关于我们</a>
            </li>

            <li
              class="nav-item nav-toggle"
              @mouseenter="handleMouseEnter(6)"
              @mouseleave="handleMouseLeave"
              :class="{ hover: hoverKey == 6 }"
              @click="
                () => {
                  this.handleNews()
                }
              "
            >
              <a class="nav-link" href="javascript:;">行业动态</a>
            </li>


            
            <li class="nav-item opts">
              <span  :class="['btnRegister']">
                <a href="https://apipecloud.veiban.com/register" rel='nofollow' style="text-decoration: none;color:#0a58ca;"  target="_blank">注册</a>
              </span>
              <span  :class="['btnLogin', isTop ? '' : 'active']">
               <a href="https://apipecloud.veiban.com/login" rel='nofollow' style="text-decoration: none;color:white !important;" target="_blank">登录</a>
              </span>

            </li>
            <!-- <li class="nav-item opts">
              <span :class="['btnRegister']">注册</span>
            </li> -->
          </ul>
        </div>
      </div>

      <div class="d-block d-lg-none defineBoard">
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <el-collapse
            v-model="activeNames"
            @change="handleChange"
            class="inner_collapse"
          >
            <div class="single-item"  >首页</div>
            <el-collapse-item title="产品中心" name="1">
              <div class="tit">MES</div>
              <div class="tit">WMS</div>
              <div class="tit">SCM</div>
              <div class="tit">CRM</div>
            </el-collapse-item>
            <el-collapse-item title="解决方案" name="2">
              <div class="tit">智能办公解决方案</div>
              <div class="tit">智能制造解决方案</div>
              <div class="tit">智慧园区解决方案</div>
            </el-collapse-item>
            <div class="single-item">客户案例</div>
            <el-collapse-item title="了解我们" name="3">
              <div class="tit">SCM</div>
              <div class="tit">CRM</div>
            </el-collapse-item>
            <div class="single-item">联系我们</div>
          </el-collapse>
        </div>
      </div>
    </nav>

    <!-- 导航 手机端 -->
    <nav
      class="navbar sticky-top bg-body-tertiary navbar-expand-lg d-block d-lg-none"
      v-if="true"
    >
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img
            src="@/assets/newSite/topNav/logoactive.png"
            alt=""
            style="width: 100px"
          />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContentss"
          aria-controls="navbarSupportedContentss"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="d-block d-lg-none defineBoard">
        <div class="collapse navbar-collapse" id="navbarSupportedContentss">
          <el-collapse
            v-model="activeNames"
            @change="handleChange"
            class="inner_collapse"
          >
            <div class="single-item activepress" @click="hanleHome()">首页</div>
            <el-collapse-item  class="first fis" title="产品中心" name="1">
              <div style="padding-left:10px;">
              <div class="tit activepress" @click="()=>{this.openNewWindow('/mes')}">智能制造MES</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/wms')}">仓储管理WMS</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/isc')}">集成供应链ISC</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/erp')}">企业资源计划ERP</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/plm')}">研发管理PLM</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/hrm')}">人力资源HRM</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/crm')}">客户关系CRM</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/fims')}">财务管理FIMS</div>
              <div class="tit activepress" @click="()=>{this.openNewWindow('/ibms')}">智慧园区IBMS</div>
            </div>
            </el-collapse-item>
            <div class="single-item activepress" @click="()=>{this.openNewWindow('/case');}">成功案列</div>
            <div class="single-item activepress" @click="()=>{this.openNewWindow('/contact');}">关于我们</div>
            <el-collapse-item class="first" title="行业动态" name="3">
              <div style="padding-left:10px;">
                <div class="tit activepress" @click="()=>{this.openNewWindow('/news?type=0');}">行业动态</div>
                <div class="tit activepress" @click="()=>{this.openNewWindow('/news?type=1');}">公司动态</div>
            </div>
            </el-collapse-item>
            
          </el-collapse>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>


export default {
  components: {

  },
  data() {
    return {
      hoverKey: -1,
      activeNames: ["1"],
      RoutName: "",
      isTop: true,
      downImg: require("@/assets/newSite/topNav/down.png"),
      downImgw: require("@/assets/newSite/topNav/down-s.png"),
    };
  },

  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
  created() {
    this.RoutName = this.$route.name;
  },
  methods: {
    handleLogin(){
     window.open('https://apipecloud.veiban.com/login')
    },
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        // console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
      } else {
        // console.log("header  滚动条向下滚动了！");
        this.isTop = false;
      }
      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        // console.log("header 到了最顶部");
        this.isTop = true;
      }
    },
   hanleHome(){ 
      this.$router.push('/') 
   },
    handleBrif() {
      this.openNewWindow('/aboutUs')
    },
    handleNews() {
      this.openNewWindow('/news')
    },
    handleChange() {},
    handleMouseEnter(val) {
      this.hoverKey = val;
      this.isTop = false;
    },
    handleMouseLeave() {
      this.hoverKey = -1;
      this.isTop = true;
    },
    handleSolution() {
      // this.$router.push("/Solution");
    },
    openNewWindow(path) {
      const routeData = this.$router.resolve({
        path,
      });
      window.open(routeData.href, '_blank');
    },
    handleMade() {
      // this.openNewWindow('/madeSolution')
      // this.$router.push("/madeSolution");
    },
    handleOffice() {
      // this.openNewWindow('/officeSolution')
    },
    handlePark() {
      // this.openNewWindow('/parkSolution')
    },
  },
};
</script>

<style lang="less"  >

/* 下面我们会解释这些 class 是做什么的 */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
  
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


.activepress:active{
  color: #262626 !important;
  background-color: #eee !important;
 
 
}

.first >div>div{
  font-weight: 700;
}
  .el-collapse-item__content{
  padding-bottom: 0 !important;
}
.whiteStyle {
  a {
    color: white !important;
    font-family: Source Han Sans CN;
  }
}
.nali {
  position: relative;
  .ul {
    padding-top: 10px;
    position: absolute;
    bottom: -100px;
    left: -70px;
    width: 200px;
    li {
      cursor: pointer;
      margin: 8px 0;
      font-size: 14px;
      &:hover {
        text-decoration: underline;
        color: #1584ff;
      }
    }
  }
}
.opts {
  display: flex;
  align-items: center;
  .btnLogin {
    font-size: 14px;
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: white;
    border-radius: 16px;
    // border: 1px solid white;
    background: #1484ff;
    box-sizing: border-box;
    cursor: pointer;
    &:hover{
      color: #1484ff !important;
      border: 1px solid #1484ff !important;
    }
  }
  .active {
    color: white !important;
    // border: 1px solid black !important;
    
  }
  .btnRegister {
    cursor: pointer;
    box-sizing: border-box;
    font-size: 14px;
    width: 80px;
    height: 30px;
    text-align: center;
    margin-right: 20px;
    border-radius: 16px;
    line-height: 30px;
    color: #0a58ca !important;
    border: 1px solid #1484ff ;
    a{
      color: #1484ff !important;
    }
    &:hover{
       color: #0a58ca;
    }
  }
}

.navbar {
  .el-collapse-item__header {
    padding: 0 10px !important;
  }
  .product-borad {
    // opacity: 0.65;
    position: absolute;
    background: white;
    // border: 1px solid red;
    width: 400px;
    overflow: hidden;
    height: 250px;
    top: 72px;
    text-align: left;
    left: 48%;
    box-sizing: border-box;
    .main-conten {
      // width: 1200px;
      margin: 0 auto;
      padding: 20px;
      // display: flex;
      // justify-content: space-between;
      .pic {
        width: 395px;
        height: 160px;
      }
      .content {
        padding: 0 20px;
        display: flex;
        .items {
          flex: 1;
          padding-left: 20px;
          div {
            padding: 10px 0;
            color: #666666;
            font-size: 14px;
            font-family: "思源黑体";
            &:hover {
              cursor: pointer;
              color: #1584ff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .resolve-borad {
    // opacity: 0.6;
    position: absolute;
    background: white;
    // border: 1px solid red;
    width: 100%;
    overflow: hidden;
    height: 120px;
    top: 72px;
    text-align: left;
    left: 0;
    box-sizing: border-box;
    .main-conten {
      width: 1200px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      position: relative;
      // justify-content: space-between;
      .ways {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 402px;
        top: 22px;
        .item {
          padding: 5px 0;
          color: #666666;
          font-size: 14px;
          font-family: "思源黑体";
          &:hover {
            cursor: pointer;
            color: #1584ff;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .about-borad {
    // opacity: 0.6;
    position: absolute;
    background: white;
    // border: 1px solid red;
    width: 100%;
    overflow: hidden;
    height: 120px;
    top: 72px;
    text-align: left;
    left: 0;
    box-sizing: border-box;
    .main-conten {
      width: 1200px;
      margin: 0 auto;
      padding: 20px;
      display: flex;
      justify-content: space-between;
      div {
        padding: 5px 0;
        color: #666666;
        font-size: 14px;
        font-family: "思源黑体";
        &:hover {
          cursor: pointer;
          color: #1584ff;
          text-decoration: underline;
        }
      }
    }
  }

  .defineBoard {
    width: 100%;
    .inner_collapse {
      margin-top: 10px;
      .single-item {
        background: white;
        height: 48px;
        line-height: 48px;
        text-align: left;
        font-size: 13px;
        padding: 0 10px;
        cursor: pointer;
        font-weight: 700;
      }
      .el-collapse-item {
        background-color: #f8f9fa;
        .el-collapse-item__header {
          padding: 0 10px;
        }
        .tit {
          text-align: left;
          padding: 4px 10px;
        }
      }
    }
  }

  .nav-item {
    margin: 0 2rem;
    position: relative;
    height: 72px;
    a {
      color: black;
    }
  }

  .nav-item.hover {
    transition: all 0.2s ease-in;
    .css_translateZ {
      transition: all 0.2s ease-out;
      transform: rotate(180deg);
    }
  }

  .nav-item::after {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    right: 0;
    height: 4px;
    width: 100%;
    background: #1584ff;
    transform: scaleX(0);
    transition: transform 0.2s ease-in-out;
  }

  .nav-item::after {
    bottom: 0;
    transform-origin: center right;
  }

  .nav-item.hover::after {
    transform-origin: center left;
    transform: scaleX(1);
  }

  .nav-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .css_translateZ {
    width: 24px;
    height: 24px;
  }
}
</style>
