<template>
  <div id="app">
    <router-view v-if="isRouterAlive" :key="$route.path + $route.query.time" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false; //
      this.$nextTick(function () {
        this.isRouterAlive = true; //
      });
    },
  },
  mounted () {
    window.addEventListener('message', (event) => {
      // console.log('message', event.origin)
      // 判断消息来源
      const arr = ['http://192.168.3.156', 'http://192.168.3.197', 'https://apipecloud.veiban.com']
      const flag = arr.some(item => event.origin.includes(item))
      if (!flag) return
      // 根据接收的消息隐藏对应元素
      if (event.data.header) {
        const footer = document.querySelector('#app .opts')
        footer.style.display = 'none'
      }
    }, false);
  },
};
</script>

<style lang="less">
@import "./styles/swiper.css";
@import "./styles/public.less";

@media (min-width: 1501px) {
  @import "./styles/lg.less";
}
@media (min-width: 993px) and (max-width: 1500px) {
  @import "./styles/md.less";
}
@media (min-width: 769px) and (max-width: 992px) {
  @import "./styles/sm.less";
}
@media (min-width: 300px) and (max-width: 768px) {
  @import "./styles/xs.less";
}
</style>
