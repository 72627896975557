import router from '../router'

export function nothingness(mark) {
    if (mark == 1) {
        alert("暂无更多数据");
    } else if (mark == 2) {
        alert("正在加紧开发中，敬请期待");
    }
}

export function toRegister() {
    router.push({
        name: "Register",
    });
}

export function isvalidPhone(phone) {
    const reg = /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
    return reg.test(phone)
  }

//锚定跳转
export function toguid(path_,id){
    localStorage.setItem('toId',id);
    router.push(path_);
}

/*prefix :  路由前缀 （必选）
 *index ：key （可选）
 *example:客户案例的案例1传('D',1)
 */
export function linkTo(prefix, index) {
    if(prefix==''){
        alert("跳转失败");
        return;
    }
    let name_ = prefix + index;
    router.push({
        name: name_,
    });
}


export function isBrowser() {
    let userAgent = navigator.userAgent;
    //微信内置浏览器
    if(userAgent.match(/MicroMessenger/i) == 'MicroMessenger') {
       return "MicroMessenger";
    }
    //QQ内置浏览器
    else if(userAgent.match(/QQ/i) == 'QQ') {
       return "QQ";
    }
    //Chrome
    else if(userAgent.match(/Chrome/i) == 'Chrome') {
       return "Chrome";
    }
    //Opera
    else if(userAgent.match(/Opera/i) == 'Opera') {
       return "Opera";
    }
    //Firefox
    else if(userAgent.match(/Firefox/i) == 'Firefox') {
       return "Firefox";
    }
    //Safari
    else if(userAgent.match(/Safari/i) == 'Safari') {
       return "Safari";
    }
    //IE
    else if(!!window.ActiveXObject || "ActiveXObject" in window) {
       return "IE";
    }
    else {
       return "未定义:"+userAgent;
    }
}
